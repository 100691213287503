var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.is_mounted
    ? _c("div", { staticClass: "q-pa-lg" }, [
        _c("br"),
        _vm._m(0),
        _c("br"),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4 justify-center" }, [
            _c("fieldset", [
              _vm._m(1),
              _c("table", { staticClass: "table" }, [
                _c("tbody", [
                  _c("tr", [
                    _c("td", { staticClass: "domanda" }, [
                      _vm._v("Contraente"),
                    ]),
                    _c("td", { staticClass: "risposta" }, [
                      _vm._v(_vm._s(_vm.dati_cliente.nominativo.toUpperCase())),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "domanda" }, [
                      _vm._v("Tipo di Contratto"),
                    ]),
                    _c("td", { staticClass: "risposta" }, [
                      _vm._v(_vm._s(_vm.getTipoContratto)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "domanda" }, [_vm._v("Fornitore")]),
                    _c("td", { staticClass: "risposta" }, [
                      _vm._v(_vm._s(_vm.getPropostaAttiva.fornitore)),
                    ]),
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "domanda" }, [_vm._v("Tariffa")]),
                    _c("td", { staticClass: "risposta" }, [
                      _vm._v(_vm._s(_vm.getTariffa)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("br"),
            _c("fieldset", { staticClass: "q-mb-md q-mt-sm" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", {
                  staticClass: "cl-12 col-md-8",
                  domProps: { innerHTML: _vm._s(_vm.domanda_label) },
                }),
                _c(
                  "div",
                  { staticClass: "cl-12 col-md-4", attrs: { align: "center" } },
                  [
                    _c("q-option-group", {
                      attrs: {
                        options: _vm.domanda_analisi,
                        label: _vm.domanda_analisi.label,
                        type: "radio",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$emit("qq-domanda-change")
                        },
                      },
                      model: {
                        value: _vm.is_changed,
                        callback: function ($$v) {
                          _vm.is_changed = $$v
                        },
                        expression: "is_changed",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "col-12 col-md-8 justify-center" }, [
            _c(
              "fieldset",
              [
                _vm._m(2),
                _vm.is_changed
                  ? _c("QQQuestionario", {
                      attrs: { domande: _vm.analisi_necessita },
                      on: {
                        "error-checked": function ($event) {
                          return _vm.onErrorChecked($event)
                        },
                        "qq-questionario-change": function ($event) {
                          return _vm.onDomandaChanges($event)
                        },
                      },
                      model: {
                        value: _vm.analisi_necessita,
                        callback: function ($$v) {
                          _vm.analisi_necessita = $$v
                        },
                        expression: "analisi_necessita",
                      },
                    })
                  : _vm._e(),
                !_vm.is_changed
                  ? _c("div", [
                      _c("table", { staticClass: "table" }, [
                        _c(
                          "tbody",
                          _vm._l(
                            _vm.analisi_necessita,
                            function (domanda, index) {
                              return _c("tr", { key: index }, [
                                _c("td", { staticClass: "domanda" }, [
                                  _vm._v(_vm._s(domanda.label) + " "),
                                ]),
                                _c("td", { staticClass: "risposta" }, [
                                  _vm._v(_vm._s(_vm.analizzaRisposta(domanda))),
                                ]),
                              ])
                            }
                          ),
                          0
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _c("br"),
          ]),
          _vm._m(3),
        ]),
        _c("div", { staticClass: "row justify-center" }, [
          _c(
            "div",
            { staticClass: "col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "INDIETRO",
                  color: "blue-grey",
                  icon: "undo",
                  size: "md",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.gotoStart()
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-md-4", attrs: { align: "center" } },
            [
              _c("QQButton", {
                attrs: {
                  label: "AVANTI",
                  color: "blue-grey",
                  icon: "mdi-arrow-right-bold",
                  size: "md",
                  icon_align: "right",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onAvantiClicked.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
        _c("br"),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { align: "center" } }, [
      _c("span", { staticClass: "text-h4" }, [
        _vm._v("Rinnovo Contratto"),
        _c("br"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("h5", { staticClass: "q-mt-sm" }, [_vm._v("Dati del Contratto")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", [
      _c("h5", { staticClass: "q-mt-sm" }, [_vm._v("Analisi necessità")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12 col-md-12 justify-center" }, [
      _c("hr"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }